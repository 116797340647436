<template>
	<div :class="{ contain: true, mobile: isMobile }">
		<div class="d-flex  flex-wrap">
				
					<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openInterchainReward"  >
					<div class="PhotographyImage d-flex flex-row" >
						<img src="@/assets/promoImage/Uptick_c11.jpg" alt="" style="object-fit: cover;"/>
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2" :class="{darkFont:$vuetify.theme.dark }">
								Claim Your Game of Interchain NFTs Reward
							</div>
						</div>
					</div>
				</div>	
					<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openInterChain">
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/promo10s.jpg" alt="" />
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2">
								Game of Interchain NFTs 
							</div>
						
						</div>
					</div>
				</div>
				
				
			
					
		
				<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openAirDrop" >
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/airDrop.jpg" alt="" style="object-fit: cover;" />
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2">
								$UPTICK Airdrop Supplementary Campaign | Uptick Governance Supporters
							</div>
						
						</div>
					</div>
				</div>
				<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openOfficial">
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/Uptick_c7.jpg" alt="" />
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2">
								$UPTICK Airdrop Supplementary Campaign | Uptick Official NFT Holders
							</div>
						
						</div>
					</div>
				</div>
				
			
			
					
					
		
				<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openCreator">
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/Uptick_c8.jpg" alt="" />
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2">
								$UPTICK Airdrop Supplementary Campaign | Featured Creator NFT Holders
							</div>
						
						</div>
					</div>
				</div>
				<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openCollectionHtml">
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/Uptick_c3.png" alt="" />
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2">
								Uptick & IRISnet NFT Collection Launchpad Event
							</div>
							<div class="time mb-lg-0 mb-n2">
								 2023.2.8-2023.5.8 23:59 UTC+8
							</div>
							<div class="timer d-flex flex-lg-row"  v-if="!isConstellationEnd">
								<div class="days">
									<div class="top">
										<span class="mr-1">{{ d1 }}</span>
										<span class="mr-2">{{ d2 }}</span>
									</div>
									<div class="bom">DAY</div>
								</div>
								<div class="mao">:</div>
								<div class="hours">
									<div class="top">
										<span class="mr-1 ml-2">{{ h1 }}</span>
										<span class="mr-2">{{ h2 }}</span>
									</div>
									<div class="bom">HR</div>
								</div>
								<div class="mao">:</div>
								<div class="minutes">
									<div class="top">
										<span class="ml-2 mr-1">{{ m1 }}</span>
										<span class="mr-2">{{ m2 }}</span>
									</div>
									<div class="bom">MIN</div>
								</div>
								<div class="mao">:</div>
								<div class="seconds">
									<div class="top">
										<span class="ml-2 mr-1">{{ s1 }}</span>
										<span class="mr-2">{{ s2 }}</span>
									</div>
									<div class="bom">SEC</div>
								</div>
							</div>
						</div>
					</div>
				</div>		
				
				
				
					
		
				<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openThanksgiving"  style="background-color: #f4f4f4">
					<div class="PhotographyImage d-flex flex-row" >
						<img src="@/assets/promoImage/Thanksgiving_s2.png" alt="" style="object-fit: cover;opacity: 0.5"/>
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2">
								Uptick Thanksgiving Campaign
							</div>
							<div class="time mb-lg-0 mb-n2">
								2022.11.23 12:00  - 2022.11.28 12:00 UTC+8
							</div>
							<div class="timer d-flex flex-lg-row" v-if="!isPhotographyEnd">
								<div class="days">
									<div class="top">
										<span class="mr-1">{{ d3 }}</span>
										<span class="mr-2">{{ d4 }}</span>
									</div>
									<div class="bom" style="width: 74%">DAY</div>
								</div>
								<div class="mao">:</div>
								<div class="hours">
									<div class="top">
										<span class="mr-1 ml-2">{{ h3 }}</span>
										<span class="mr-2">{{ h4 }}</span>
									</div>
									<div class="bom">HR</div>
								</div>
								<div class="mao">:</div>
								<div class="minutes">
									<div class="top">
										<span class="ml-2 mr-1">{{ m3 }}</span>
										<span class="mr-2">{{ m4 }}</span>
									</div>
									<div class="bom">MIN</div>
								</div>
								<div class="mao">:</div>
								<div class="seconds">
									<div class="top">
										<span class="ml-2 mr-1">{{ s3 }}</span>
										<span class="mr-2">{{ s4 }}</span>
									</div>
									<div class="bom">SEC</div>
								</div>
							</div>
						</div>
					</div>
				</div>	
				<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openCollectionV2Html"  style="background-color: #f4f4f4">
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/uptick_c5.jpeg" alt=""  style="object-fit: cover;opacity: 0.5"/>
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2">
								Twelve Constellation Series: Uptick Zodiac | Gen 2
							</div>
							<div class="time mb-lg-0 mb-n2">
								Claim Time: 2023 3.16 17:00 - 4.16 17:00 UTC+8
							</div>
							<div class="timer d-flex flex-lg-row"  v-if="!isConstellationV2End">
								<div class="days">
									<div class="top">
										<span class="mr-1">{{ d5 }}</span>
										<span class="mr-2">{{ d6 }}</span>
									</div>
									<div class="bom">DAY</div>
								</div>
								<div class="mao">:</div>
								<div class="hours">
									<div class="top">
										<span class="mr-1 ml-2">{{ h5 }}</span>
										<span class="mr-2">{{ h6 }}</span>
									</div>
									<div class="bom">HR</div>
								</div>
								<div class="mao">:</div>
								<div class="minutes">
									<div class="top">
										<span class="ml-2 mr-1">{{ m5 }}</span>
										<span class="mr-2">{{ m6 }}</span>
									</div>
									<div class="bom">MIN</div>
								</div>
								<div class="mao">:</div>
								<div class="seconds">
									<div class="top">
										<span class="ml-2 mr-1">{{ s5 }}</span>
										<span class="mr-2">{{ s6 }}</span>
									</div>
									<div class="bom">SEC</div>
								</div>
							</div>
						</div>
					</div>
				</div>
					
				
				
			
				
				<!-- <div
          v-else
          class="right mr-lg-5 pt-lg-5 mb-4"
          @click="openPhotographyHtml"
        >
          <div class="PhotographyImage d-flex flex-row">
            <img src="@/assets/promoImage/image_photography.png" alt="" />
            <div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
              <div class="title mb-lg-4 mb-2">
                Uptick NFT & IRISnet Photography Competition
              </div>
              <div class="time mb-lg-0 mb-n2">
                2022 2.18 12:00 - 3.4 12:00 UTC+8
              </div>
              <div class="timer d-flex flex-lg-row">
                <div class="days">
                  <div class="top">
                    <span class="mr-1">{{ d1 }}</span>
                    <span class="mr-2">{{ d2 }}</span>
                  </div>
                  <div class="bom">DAY</div>
                </div>
                <div class="mao">:</div>
                <div class="hours">
                  <div class="top">
                    <span class="mr-1 ml-2">{{ h1 }}</span>
                    <span class="mr-2">{{ h2 }}</span>
                  </div>
                  <div class="bom">HR</div>
                </div>
                <div class="mao">:</div>
                <div class="minutes">
                  <div class="top">
                    <span class="ml-2 mr-1">{{ m1 }}</span>
                    <span class="mr-2">{{ m2 }}</span>
                  </div>
                  <div class="bom">MIN</div>
                </div>
                <div class="mao">:</div>
                <div class="seconds">
                  <div class="top">
                    <span class="ml-2 mr-1">{{ s1 }}</span>
                    <span class="mr-2">{{ s2 }}</span>
                  </div>
                  <div class="bom">SEC</div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
			
				<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openAtomHtml" style="background-color: #f4f4f4">
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/Uptick_Atom.png" alt="" style="object-fit: cover;opacity: 0.5" />
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2">
								The ATOM Rewards Campaign
							</div>
							<div class="time mb-lg-0 mb-n2">
								2022 8.15 12:00 - 2022 10.14 12:00 UTC+8
							</div>
							<div class="timer d-flex flex-lg-row" v-if="false">
								<div class="days">
									<div class="top">
										<span class="mr-1">{{ d3 }}</span>
										<span class="mr-2">{{ d4 }}</span>
									</div>
									<div class="bom" style="width: 74%">DAY</div>
								</div>
								<div class="mao">:</div>
								<div class="hours">
									<div class="top">
										<span class="mr-1 ml-2">{{ h3 }}</span>
										<span class="mr-2">{{ h4 }}</span>
									</div>
									<div class="bom">HR</div>
								</div>
								<div class="mao">:</div>
								<div class="minutes">
									<div class="top">
										<span class="ml-2 mr-1">{{ m3 }}</span>
										<span class="mr-2">{{ m4 }}</span>
									</div>
									<div class="bom">MIN</div>
								</div>
								<div class="mao">:</div>
								<div class="seconds">
									<div class="top">
										<span class="ml-2 mr-1">{{ s3 }}</span>
										<span class="mr-2">{{ s4 }}</span>
									</div>
									<div class="bom">SEC</div>
								</div>
							</div>
						</div>
					</div>
				</div>	
				<div class="right mr-lg-5 pt-lg-5 mb-4" style="background-color: #f4f4f4" @click="openHalloweenHtml">
				
				<div class="PhotographyImage d-flex flex-row">
					<img src="@/assets/promoImage/Uptick_ws.png" style="object-fit: cover; opacity: 0.5;" alt="" />
					<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
						<div class="title mb-lg-4 mb-2">
							Uptick Halloween Campaign
						</div>
						<div class="time mb-lg-0 mb-n2">
							2022.10.28 18:00 - 2022.11.2 12:00 (UTC+8) 
						</div>
						<div class="timer d-flex flex-lg-row" v-if="false">
							<div class="days">
								<div class="top">
									<span class="mr-1">{{ d3 }}</span>
									<span class="mr-2">{{ d4}}</span>
								</div>
								<div class="bom">DAY</div>
							</div>
							<div class="mao">:</div>
							<div class="hours">
								<div class="top">
									<span class="mr-1 ml-2">{{ h3 }}</span>
									<span class="mr-2">{{ h4 }}</span>
								</div>
								<div class="bom">HR</div>
							</div>
							<div class="mao">:</div>
							<div class="minutes">
								<div class="top">
									<span class="ml-2 mr-1">{{ m3 }}</span>
									<span class="mr-2">{{ m4 }}</span>
								</div>
								<div class="bom">MIN</div>
							</div>
							<div class="mao">:</div>
							<div class="seconds">
								<div class="top">
									<span class="ml-2 mr-1">{{ s3 }}</span>
									<span class="mr-2">{{ s4 }}</span>
								</div>
								<div class="bom">SEC</div>
							</div>
						</div>
					</div>
				</div>
			</div>
				
				

				

			
				
				<!-- <div v-else class="right mr-lg-5 mb-3 pt-lg-5" @click="openPromoHtml">
          <div class="PhotographyImage d-flex flex-row">
            <img src="@/assets/promoImage/Uptick Zodiac.gif" alt="" />
            <div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
              <div class="title mb-lg-4 mb-2">
                Twelve Constellation Series: Uptick Zodiac
              </div>
              <div class="time mb-lg-0 mb-n2">
                2022 2.18 20:00 - 4.18 20:00 UTC+8
              </div>
              <div class="timer d-flex flex-lg-row">
                <div class="days">
                  <div class="top">
                    <span class="mr-1">{{ d3 }}</span>
                    <span class="mr-2">{{ d4 }}</span>
                  </div>
                  <div class="bom" style="width: 74%">DAY</div>
                </div>
                <div class="mao">:</div>
                <div class="hours">
                  <div class="top">
                    <span class="mr-1 ml-2">{{ h3 }}</span>
                    <span class="mr-2">{{ h4 }}</span>
                  </div>
                  <div class="bom">HR</div>
                </div>
                <div class="mao">:</div>
                <div class="minutes">
                  <div class="top">
                    <span class="ml-2 mr-1">{{ m3 }}</span>
                    <span class="mr-2">{{ m4 }}</span>
                  </div>
                  <div class="bom">MIN</div>
                </div>
                <div class="mao">:</div>
                <div class="seconds">
                  <div class="top">
                    <span class="ml-2 mr-1">{{ s3 }}</span>
                    <span class="mr-2">{{ s4 }}</span>
                  </div>
                  <div class="bom">SEC</div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
			
					<div class="right mr-lg-5 pt-lg-5 mb-4" @click="openweddingHtml" style="background-color: #f4f4f4">
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/image_wedding.jpg" style="object-fit: cover;opacity: 0.5"
							alt="" />
						<div class="d-flex flex-column mt-lg-7 mt-2 ml-lg-5 ml-2">
							<div class="title mb-lg-4 mb-2" v-if="isMobile" style="max-width: 398px">
								MetaWedding - Rita & André
							</div>
							<div class="title mb-lg-4 mb-2" v-if="!isMobile" style="width: 398px">
								MetaWedding - Rita & André
							</div>
							<div class="time mb-lg-0 mb-n2">2022 3.20 6:30 UTC+8</div>

						</div>
					</div>
				</div>
					<div class="right mr-lg-5 pt-lg-5 mb-4" style="background-color: #f4f4f4" @click="openPhotographyHtml">
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/image_photography.png" style="opacity: 0.5" alt="" />
						<div class="d-flex flex-column mt-7 ml-lg-5 ml-2">
							<div class="title mb-4">
								Uptick NFT & IRISnet Photography Competition
							</div>
							<div class="time">2022 2.18 12:00 - 3.4 12:00 UTC+8</div>
						</div>
					</div>
				</div>
					
				
			
					
				
						<div class="right mr-lg-5 mb-3 pt-lg-5" style="background-color: #f4f4f4" @click="openPromoHtml">
					<div class="PhotographyImage d-flex flex-row">
						<img src="@/assets/promoImage/Uptick Zodiac.gif" alt="" style="opacity: 0.5" />
						<div class="d-flex flex-column mt-lg-16 mt-7 ml-lg-5 ml-2">
							<div class="title mb-4">
								Twelve Constellation Series: Uptick Zodiac
							</div>
							<div class="time">2022 3.18 20:00 - 4.18 20:00 UTC+8</div>
						</div>
					</div>
				</div>
				
			
					
					
			</div>
	
	</div>
</template>

<script>
	import api from "@/api"
	import { getSettingArr } from "@/utils/helper";
	export default {

		data() {
			return {
				dataLIst: [{
					src: "",
					id: 1,
					title: "",
					time: "",
				}, ],
				isConstellationEnd: false,
				isPhotographyEnd: false,
				isConstellationV2End:false,
				d1: "",
				d2: "",
				h1: "",
				h2: "",
				m1: "",
				m2: "",
				s1: "",
				s2: "",
				d3: "",
				d4: "",
				h3: "",
				h4: "",
				m3: "",
				m4: "",
				s3: "",
				s4: "",
				d5: "",
				d6: "",
				h5: "",
				h6: "",
				m5: "",
				m6: "",
				s5: "",
				s6: "",
				secondsPhotography: "",
				now: "",
				endPhotography: "",
				endConstellation: "",
				endConstellationV2: "",
				secondsConstellation: "",
			};
		},
		computed: {
			isMobile: function() {
				return this.$vuetify.breakpoint.mobile;
			},
		},
		async mounted() {
			this.showNoRead();
			this.Time();
			var timestamp = Date.parse(new Date()).toString();
			let nowTimeStamp = timestamp.substr(0, 10);
			// 星座结束时间
			let constellationEndTimeStamp = 1673366399;
			// 摄像作品结束时间
			let photographyEndTimeStamp = 1669608000;
			//星座第二期结束时间
			let isConstellationV2EndTimeStamp = 1678946400;
			if (nowTimeStamp > isConstellationV2EndTimeStamp) {
				this.isConstellationV2End = true;
				console.log(nowTimeStamp);
				console.log(photographyEndTimeStamp);
				console.log("结束");
			} else {
				this.isConstellationV2End = false;
				console.log(nowTimeStamp);
				console.log(photographyEndTimeStamp);
				console.log("开始");
			}

			if (nowTimeStamp > photographyEndTimeStamp) {
				this.isPhotographyEnd = true;
				console.log(nowTimeStamp);
				console.log(photographyEndTimeStamp);
				console.log("结束");
			} else {
				this.isPhotographyEnd = false;
				console.log(nowTimeStamp);
				console.log(photographyEndTimeStamp);
				console.log("开始");
			}

			if (nowTimeStamp > constellationEndTimeStamp) {
				this.isConstellationEnd = true;
				console.log(nowTimeStamp);
				console.log(constellationEndTimeStamp);
				console.log("结束");
			} else {
				this.isConstellationEnd = false;
				console.log(nowTimeStamp);
				console.log(constellationEndTimeStamp);
				console.log("开始");
			}
		},
		methods: {
			getTime() {
				// 摄像作品结束时间
				let photographyEndTimeStamp = 1673366399000;
				let constellationEndTimeStamp = 1669608000000;
				let isConstellationV2EndTimeStamp = 1678946400000;
				this.now = new Date().valueOf();
				this.endPhotography = photographyEndTimeStamp;
				this.endConstellation = constellationEndTimeStamp;
				this.endConstellationV2 = isConstellationV2EndTimeStamp;
				//摄影
				if (this.now < this.endPhotography) {
					this.secondsPhotography = (this.endPhotography - this.now) / 1000;
				}
				//摄影
				if (this.now < this.endConstellationV2) {
					this.secondsConstellationV2 = (this.endConstellationV2 - this.now) / 1000;
				}
				//竞赛
				if (this.now < this.endConstellation) {
					this.secondsConstellation = (this.endConstellation - this.now) / 1000;
				}
			},
			// 天 时 分 秒 格式化函数
			countDown() {
				let d = parseInt(this.secondsPhotography / (24 * 60 * 60));
				let day = d < 10 ? "0" + d : d;
				this.d1 = String(day).split("")[0];
				this.d2 = String(day).split("")[1];
				//let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
				let h = parseInt((this.secondsPhotography / (60 * 60)) % 24);
				let hour = h < 10 ? "0" + h : h;
				this.h1 = String(hour).split("")[0];
				this.h2 = String(hour).split("")[1];
				let m = parseInt((this.secondsPhotography / 60) % 60);
				let minutes = m < 10 ? "0" + m : m;
				this.m1 = String(minutes).split("")[0];
				this.m2 = String(minutes).split("")[1];
				let s = parseInt(this.secondsPhotography % 60);
				let second = s < 10 ? "0" + s : s;
				this.s1 = String(second).split("")[0];
				this.s2 = String(second).split("")[1];
			},
			countDown1() {
				let d = parseInt(this.secondsConstellation / (24 * 60 * 60));
				let day = d < 10 ? "0" + d : d;
				this.d3 = String(day).split("")[0];
				this.d4 = String(day).split("")[1];
				//let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
				let h = parseInt((this.secondsConstellation / (60 * 60)) % 24);
				let hour = h < 10 ? "0" + h : h;
				this.h3 = String(hour).split("")[0];
				this.h4 = String(hour).split("")[1];
				let m = parseInt((this.secondsConstellation / 60) % 60);
				let minutes = m < 10 ? "0" + m : m;
				this.m3 = String(minutes).split("")[0];
				this.m4 = String(minutes).split("")[1];
				let s = parseInt(this.secondsConstellation % 60);
				let second = s < 10 ? "0" + s : s;
				this.s3 = String(second).split("")[0];
				this.s4 = String(second).split("")[1];
			},
			countDown2() {
				let d = parseInt(this.secondsConstellationV2 / (24 * 60 * 60));
				let day = d < 10 ? "0" + d : d;
				this.d5 = String(day).split("")[0];
				this.d6 = String(day).split("")[1];
				//let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
				let h = parseInt((this.secondsConstellationV2 / (60 * 60)) % 24);
				let hour = h < 10 ? "0" + h : h;
				this.h5 = String(hour).split("")[0];
				this.h6 = String(hour).split("")[1];
				let m = parseInt((this.secondsConstellationV2 / 60) % 60);
				let minutes = m < 10 ? "0" + m : m;
				this.m5 = String(minutes).split("")[0];
				this.m6 = String(minutes).split("")[1];
				let s = parseInt(this.secondsConstellationV2 % 60);
				let second = s < 10 ? "0" + s : s;
				this.s5 = String(second).split("")[0];
				this.s6 = String(second).split("")[1];
			},
			
			//定时器没过1秒参数减1
			Time() {
				setInterval(() => {
					if (this.secondsPhotography > 0) {
						this.secondsPhotography -= 1;
						this.countDown();
					} else {
						this.getTime();
					}
					if (this.secondsConstellation > 0) {
						this.secondsConstellation -= 1;
						this.countDown1();
					} else {
						this.getTime();
					}
					if (this.secondsConstellationV2 > 0) {
						this.secondsConstellationV2 -= 1;
						this.countDown2();
					} else {
						this.getTime();
					}

				}, 1000);
			},
			openweddingHtml() {
				this.$gtag.event('活动页-9', { 'event_category': 'Click', 'event_label': '活动页-9' })
				console.log("openweddingHtml");
				this.$router.push({
					name: "Wedding"
				});
			},
			openPhotographyHtml() {
				this.$gtag.event('活动页-10', { 'event_category': 'Click', 'event_label': '活动页-10' })
				console.log("openPhotographyHtml");
				this.$router.push({
					name: "Photography"
				});
			},
			openAtomHtml() {
				this.$gtag.event('活动页-7', { 'event_category': 'Click', 'event_label': '活动页-7' })
				this.$router.push({
					name: "ATOMRewards"
				});
				console.log("openAtomHtml");
			},
			openCollectionHtml() {
				this.$gtag.event('活动页-4', { 'event_category': 'Click', 'event_label': '活动页-4' })
				console.log("openCollectionHtml");
				this.$router.push({
					name: "CollectionHtml"
				});

			},
			openCollectionV2Html(){
				this.$gtag.event('活动页-6', { 'event_category': 'Click', 'event_label': '活动页-6' })
				this.$router.push({
					name: "UptickZodiac"
				});
			},
			openOfficial(){
				this.$gtag.event('活动页-2', { 'event_category': 'Click', 'event_label': '活动页-2' })
				this.$router.push({
					name: "OfficialAirdrop"
				});
			},
			openCreator(){
				this.$gtag.event('活动页-3', { 'event_category': 'Click', 'event_label': '活动页-3' })
			this.$router.push({
					name: "CreatorAirdrop"
				});
			},
			openAirDrop(){
				this.$gtag.event('活动页-1', { 'event_category': 'Click', 'event_label': '活动页-1' })
				this.$router.push({
					name: "Airdrop"
				});
			},
			openInterChain(){
				this.$gtag.event('活动页-Game of Interchain NFTs ', { 'event_category': 'Click', 'event_label': '活动页-Game of Interchain NFTs' })
				this.$router.push({
					name: "InterchainNFTs"
				});

			},
			openInterchainReward(){
				this.$gtag.event('活动页-Claim Your Game of Interchain NFTs Reward', { 'event_category': 'Click', 'event_label': '活动页-Claim Your Game of Interchain NFTs Reward' })
				this.$router.push({
					name: "InterchainReward"
				});
			},
			openThanksgiving(){
				this.$gtag.event('活动页-5', { 'event_category': 'Click', 'event_label': '活动页-5' })
				this.$router.push({
					name: "Thanksgiving"
				});
	
			},
			openHalloweenHtml(){
				this.$gtag.event('活动页-8', { 'event_category': 'Click', 'event_label': '活动页-8' })
				this.$router.push({
					name: "Halloween"
				});
			},
			openPromoHtml() {
				this.$gtag.event('活动页-11', { 'event_category': 'Click', 'event_label': '活动页-11' })
				console.log("openPromoHtml");
				this.$router.push({
					name: "Constellation"
				});
			},
			async showNoRead() {
				const json = localStorage.getItem("key_user");
				if (json) {
					let res2 = await api.home.isNewMessage({
						userAddress: this.$store.state.did,
						pushTypes:getSettingArr()
					});
					let newMsgCount = res2.data;


					// 有新消息进行弹窗
					if (newMsgCount > 0) {
						let params = {
							pageSize: 10,
							pageNumber: 1,
							owner: this.$store.state.did,
							isRead: false,
							pushTypes:getSettingArr()
						};
						let res = await api.home.sysMessage(params)
						console.log("wxl --- sysMessage", res)
						if (res.data) {
							let list = res.data.list
							for (let index = 0; index < list.length; index++) {
								setTimeout(() => {
									this.$mtip({
										title: list[index].pushContract,
									});
								}, 1000);

							}
						}
					}
				}
				window.eventBus.$emit("ViewMessage", true);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.contain {
	
			//  margin-top: 45px;

			.right {
				width: 48%;
				height: 250px;
				//      background-image: linear-gradient(
				// 	#ffffff,
				// 	#ffffff),
				// linear-gradient(
				// 	#6f58d9,
				// 	#6f58d9);
				background-blend-mode: normal, normal;
				box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
				border-radius: 5px;
				cursor: pointer;

				.PhotographyImage {
					margin-left: 25px;

					img {
						width: 157px;
						height: 209px;
						display: block;
						object-fit: contain;
					}

					.timer {
						// max-width:305px;
						// margin:17px auto 21px;
						justify-content: flex-start;
						margin-top: 17px;

						.mao {
							font-family: "\5FAE\8F6F\96C5\9ED1";
							font-size: 18px;
							font-weight: bold;
							font-stretch: normal;
							line-height: 30px;
							letter-spacing: 0px;
							color: #270645;
						}

						.bom {
							font-family: "\5FAE\8F6F\96C5\9ED1";
							font-size: 11px;
							font-weight: normal;
							font-stretch: normal;
							line-height: 20px;
							letter-spacing: 0px;
							color: #766983;
							text-align: center;
							margin-top: 7px;
						}

						span {
							width: 24px;
							height: 33px;
							display: inline-block;
							text-align: center;
							line-height: 33px;
							background-color: #270645;
							border-radius: 5px;
							font-family: "\5FAE\8F6F\96C5\9ED1";
							font-size: 16px;
							font-weight: bold;
							font-stretch: normal;
							letter-spacing: 0px;
							color: #ffffff;
						}
					}

					.title {
						//width: 326px;
						height: 62px;
						font-family: "\5FAE\8F6F\96C5\9ED1";
						font-size: 25px !important;
						font-weight: bold;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #270645;
					}

					.titleAtom {
						// width: 345px;
						height: 80px;
						font-size: 25px !important;
						font-weight: bold;
						font-stretch: normal;
						letter-spacing: 0px;
						color: #270645;
					}

					.time {
						//width: 232px;
						height: 11px;
						font-family: "\5FAE\8F6F\96C5\9ED1";
						font-size: 13px;
						font-weight: normal;
						font-stretch: normal;
						line-height: 13px;
						letter-spacing: 0px;
						color: #270645;
					}
				}
			}
		

		// .content:nth-child(2) {
		//   margin-bottom: 303px;
		// }

		&.mobile {
			
				// margin-top: 30px;

				.right {
					width: 100%;
					max-height: 140px;
					padding: 12px 14px;

					.PhotographyImage {
						margin-left: 0;

						img {
							width: 88px;
							height: 117px;
						}

						.timer {
							.bom {
								margin-top: 3px;
							}

							span {
								width: 14px;
								height: 19px;
								font-family: "\5FAE\8F6F\96C5\9ED1";
								font-size: 9px;
								text-align: center;
								font-weight: bold;
								font-stretch: normal;
								display: inline-block;
								line-height: 19px;
								letter-spacing: 0px;
								color: #ffffff;
							}
						}

						.title {
							//width:154px;
							height: 36px;
							font-family: "\5FAE\8F6F\96C5\9ED1";
							font-size: 15px !important;
							font-weight: bold;
							font-stretch: normal;
							line-height: 16px !important;
							letter-spacing: 0px;
							color: #270645;
							margin-bottom: 11px;
						}

						.time {
							//width:158px;
							height: 10px;
							font-family: "\5FAE\8F6F\96C5\9ED1";
							font-size: 11px !important;
							font-weight: normal;
							font-stretch: normal;
							line-height: 12px !important;
							letter-spacing: 0px;
							color: #270645;
						}
					}
				}
			

			// .content:nth-child(2) {
			//   margin-bottom: 100px;
			// }
		}
	}
</style>
